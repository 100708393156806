import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import Image from "../../atoms/image/image.component";
import { sideBarTop, sideBarBottom } from "../../../assets/data/nav";
import styles from "./sidenav.module.scss";
import { MdLogout } from "react-icons/md";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../../../utils/utils/utils";
import { useGetUser, useUserDetail } from "../../../../hooks/user";
import { NO_PERMISSION } from "../../../../constants/permissions";
import Spinner from "../../atoms/spinner/spinner.component";
import { useStore } from "../../../../store";
import { LocalStorageKeys } from "../../../constants";

const profileImage =
  "https://res.cloudinary.com/dftu6mjmt/image/upload/v1710765786/750HoursTracker/tracker-auth_hrzsvc.png";
const logoImage =
  "https://res.cloudinary.com/dftu6mjmt/image/upload/v1710765782/750HoursTracker/tracker-logo_copq4t.png";

const SideNav = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);

  // const { userPermissions, isPermissionsLoading } = useStore();

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/signin");
  };

  const [profilePicFormatted, setProfilePicFormatted] = useState("");

  const { email, name, id } = useUserDetail();
  const { data } = useGetUser(id);

  const hasPermission = (permission) => {
    if (permission === NO_PERMISSION) {
      return true;
    } else {
      return permissions.some((p) => p.value === permission) || false;
    }
  };

  const { profilePic } = data || {};
  useEffect(() => {
    if (profilePic?.data) {
      setProfilePicFormatted(
        `data:${profilePic?.contentType};base64,${profilePic?.data}`
      );
    }
  }, [profilePic]);

  useEffect(() => {
    if (props.navValue) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [props]);

  useEffect(() => {
    const userPermissions = JSON.parse(
      localStorage.getItem(LocalStorageKeys.PERMISSIONS)
    );
    console.log(userPermissions);
    setPermissions(userPermissions);
  }, []);

  // if (isPermissionsLoading) {
  //   return <Spinner />;
  // }

  return (
    <>
      {/* mobile sidebar start */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex bg-gray-900"
          onClose={() => props.showSidebar()}
        >
          <Transition.Child
            as={Fragment}
            enter="transiton-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transiton-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transiton ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transiton ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="transiton-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transiton-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 -mr-12">
                  <button
                    className="flex items-center justify-center w-10 h-10 ml-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => props.showSidebar()}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex items-center justify-center flex-shrink-0 px-4">
                <img
                  className="object-fill md:h-24 h-16"
                  src={logoImage}
                  alt="750HrsTracker Logo"
                />
              </div>
              <div className="flex-1 h-0 mt-5 overflow-y-auto">
                <nav className="pl-8 space-y-4 pt-14">
                  {sideBarTop.map(
                    (data) =>
                      data?.permission &&
                      hasPermission(data?.permission) && (
                        <div className="text-2xl text-gray-300" key={data.id}>
                          <NavLink
                            className={`${styles.sidenav__links} ${
                              location.pathname === data.route
                                ? styles.active
                                : ""
                            }`}
                            to={data.route}
                          >
                            <i className={styles["sidenav__icon"]}>
                              {data.icon}
                            </i>
                            {data.name}
                          </NavLink>
                        </div>
                      )
                  )}
                </nav>
                <div className="pl-8 my-12 space-y-4">
                  {sideBarBottom.map((data) => (
                    <div className="text-2xl text-gray-300" key={data.id}>
                      <NavLink
                        className={`${styles.sidenav__links} ${
                          location.pathname === data.route ? styles.active : ""
                        }`}
                        to={data.route}
                      >
                        <i className={styles["sidenav__icon"]}>{data.icon}</i>
                        {data.name}
                      </NavLink>
                    </div>
                  ))}
                </div>

                <div className="flex flex-row pl-8 my-12 items-center	">
                  <div className={styles["sidenav__user--image"]}>
                    <Image
                      className={styles["sidenav__user--img"]}
                      url={profilePicFormatted || profileImage}
                      altName="logo-image"
                    />
                  </div>
                  <div className="ml-2 text-gray-300">
                    <h4 className={styles["sidenav__user--details"]}>{name}</h4>
                    <p>{email}</p>
                  </div>
                  <div
                    className="text-red-600 text-xl ml-4"
                    onClick={handleLogout}
                  >
                    <i className={styles["sidenav__user--log-icon"]}>
                      <MdLogout />
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to forces sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      {/* mobile sidebar end */}

      {/* Desktop sidebar start */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-72">
          <div className="flex flex-col flex-1 h-0">
            <div className="logo-container flex items-center justify-center flex-shrink-0 my-6 px-4 bg-white">
              <img
                className="object-fill md:h-24 h-16"
                src={logoImage}
                alt="750HrsTracker Logo"
              />
            </div>
            <div className="flex flex-col flex-1 overflow-y-auto ">
              <nav className="flex-1 py-4 pl-8 space-y-4 pt-12">
                {sideBarTop.map((data) => {
                  return (
                    data?.permission &&
                    hasPermission(data?.permission) && (
                      <div className={styles["sidenav__icon"]} key={data.id}>
                        <NavLink
                          className={`${styles.sidenav__links} ${
                            location.pathname === data.route
                              ? styles.active
                              : ""
                          }`}
                          to={data.route}
                        >
                          <i className={styles["sidenav__icon"]}>{data.icon}</i>
                          {data.name}
                        </NavLink>
                      </div>
                    )
                  );
                })}
                <div className={styles["sidenav__bottom"]}>
                  <div className={styles["sidenav__bottom--nav"]}>
                    <div className={styles["sidenav__top--nav"]}>
                      {sideBarBottom.map((data) => (
                        <div className={styles["sidenav__list"]} key={data.id}>
                          <NavLink
                            className={`${styles.sidenav__links} ${
                              location.pathname === data.route
                                ? styles.active
                                : ""
                            }`}
                            to={data.route}
                          >
                            <i className={styles["sidenav__icon"]}>
                              {data.icon}
                            </i>
                            {data.name}
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles["sidenav__user--div"]}>
                    <div className={styles["sidenav__user--image"]}>
                      <Image
                        className={styles["sidenav__user--img"]}
                        url={profilePicFormatted || profileImage}
                        altName="logo-image"
                      />
                    </div>
                    <div className={styles["sidenav__user--details"]}>
                      <h4 className={styles["sidenav__user--details"]}>
                        {name}
                      </h4>
                      <p>{email}</p>
                    </div>
                    <div
                      className={styles["sidenav__user--log"]}
                      onClick={handleLogout}
                    >
                      <i className={styles["sidenav__user--log-icon"]}>
                        <MdLogout />
                      </i>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Desktop sidebar end */}
    </>
  );
};

export default SideNav;
