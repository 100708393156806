import base from "../../libs/axios";
import baseBlob from "../../libs/axios-blob";

export const getActivityLogs = (params = {}) => {
  const {
    pageNumber = 1,
    pageSize = 10,
    propertyType = "ALL",
    WithDocuments,
    AllSupportingDocument,
    HasSupportingDocument,
    startDate,
    endDate,
    property,
    member,
    activity,
  } = params;

  let queryString = `/api/activity-logs?PageNumber=${pageNumber}&PageSize=${pageSize}&propertyType=${propertyType}`;

  if (WithDocuments !== undefined) {
    queryString += `&WithDocuments=${true}`;
  }

  if (AllSupportingDocument !== undefined) {
    queryString += `&AllSupportingDocument=${true}`;
  }

  if (HasSupportingDocument !== undefined) {
    queryString += `&HasSupportingDocument=${true}`;
  }

  if (startDate !== undefined) {
    queryString += `&StartDate=${startDate}`;
  }

  if (endDate !== undefined) {
    queryString += `&EndDate=${endDate}`;
  }
  if (property !== undefined) {
    queryString += `&property=${property}`;
  }
  if (member !== undefined) {
    queryString += `&member=${member}`;
  }
  if (activity !== undefined) {
    queryString += `&activity=${activity}`;
  }

  return base
    .get(queryString)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error fetching logs:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getSomeActivityLogs = (keyword) => {
  if (keyword) {
    return base
      .get(`/api/activity-logs/search?keyword=${keyword}`)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(
          "Error fetching logs:",
          err?.response?.data?.message || err?.message
        );
        throw err;
      });
  }
};

export const getLogCategories = () => {
  return base
    .get(`/api/log-categories/list`)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error retrieving log categories:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getLogActivities = (propertyType = "STR") => {
  return base
    .get(`/api/log-activities/list?availablePropertyType=${propertyType}`)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error retrieving log activities:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

const createLTRRecord = (data) =>
  base
    .post("/api/activity-logs", data)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error recording time:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });

const createSTRRecord = (data) =>
  base
    .post("/api/activity-logs/add-str-log", data)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error recording time:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });

export const createRecordService = {
  ltr: createLTRRecord,
  str: createSTRRecord,
};

export const editLog = (id, data) => {
  return base
    .patch(`/api/activity-logs/${id}`, data)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error updating log time:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const deleteLog = (id) => {
  return base
    .delete(`/api/activity-logs/${id}`)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error deleting log:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getLog = (id) => {
  return base
    .get(`/api/activity-logs/${id}`)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error fetching log:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const downloadDocuments = () => {
  return baseBlob
    .get(`/api/activity-logs/download-documents`)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error fetching log:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};
export const downloadReport = (params = {}) => {
  const {
    pageNumber = 1,
    pageSize = 10,
    propertyType = "ALL",
    WithDocuments,
    AllSupportingDocument,
    HasSupportingDocument,
    startDate,
    endDate,
    property,
    member,
    activity,
  } = params;

  let queryString = `/api/activity-logs/download-report?PageNumber=${pageNumber}&PageSize=${pageSize}&propertyType=${propertyType}`;

  if (WithDocuments !== undefined) {
    queryString += `&WithDocuments=${true}`;
  }

  if (AllSupportingDocument !== undefined) {
    queryString += `&AllSupportingDocument=${true}`;
  }

  if (HasSupportingDocument !== undefined) {
    queryString += `&HasSupportingDocument=${true}`;
  }

  if (startDate !== undefined) {
    queryString += `&StartDate=${startDate}`;
  }

  if (endDate !== undefined) {
    queryString += `&EndDate=${endDate}`;
  }
  if (property !== undefined) {
    queryString += `&property=${property}`;
  }
  if (member !== undefined) {
    queryString += `&member=${member}`;
  }
  if (activity !== undefined) {
    queryString += `&activity=${activity}`;
  }
  return baseBlob
    .get(queryString)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error fetching log:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};
