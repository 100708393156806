import { showToast } from "../../app/components/atoms/showToast/showToast";
import { NotificationTypes } from "../../app/constants";
import queryKeys from "../../libs/react-query/queryKeys";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  createRecordService,
  deleteLog,
  editLog,
  getActivityLogs,
  getLog,
  getLogActivities,
  getLogCategories,
  getSomeActivityLogs,
} from "../../services/activity-log";

export const useGetLogs = (params) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: [queryKeys.getLogs, params],
    queryFn: () => getActivityLogs(params),
    meta: {
      errorMessage: "Error retrieving logs",
    },
  });

  const clientData = useMemo(() => {
    return {
      logs: data?.data || [],
      count: data?.totalRecords || 0,
      currentPage: data?.pageNumber || 1,
    };
  }, [data]);

  return {
    clientData,
    isLoading: isLoading || isRefetching,
  };
};

export const useGetSomeLogs = (keyword) => {
  const { isPending, isRefetching, data } = useQuery({
    queryFn: () => keyword && getSomeActivityLogs(keyword),
    queryKey: [queryKeys.getSomeLogs, keyword],
    meta: {
      errorMessage: "Error retrieving logs",
    },
  });

  const clientData = useMemo(() => {
    return {
      searchData: data?.data || [],
      count: data?.totalRecords || 0,
      currentPage: data?.pageNumber || 1,
    };
  }, [data]);

  return { isLoading: isPending || isRefetching, clientData };
};

export const useGetLogCategories = () => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: [queryKeys.getLogCategories],
    queryFn: () => getLogCategories(),
    meta: {
      errorMessage: "Error retrieving log categories",
    },
  });

  const clientData = useMemo(() => {
    return {
      logData: data?.data || [],
    };
  }, [data]);
  return {
    clientData,
    isLoading: isLoading || isRefetching,
  };
};

export const useGetLogActivities = (propertyType) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: [queryKeys.getLogActivities],
    queryFn: () => getLogActivities(propertyType),
    meta: {
      errorMessage: "Error retrieving log activities",
    },
  });

  const clientData = useMemo(() => {
    return {
      activities: data?.data || [],
      count: data?.totalRecords || 0,
      currentPage: data?.pageNumber || 1,
    };
  }, [data]);

  return {
    clientData,
    isLoading: isLoading || isRefetching,
  };
};

export const useCreateRecord = (dataKey, data) => {
  const createRecord = async (dataKey, data) => {
    const mutationFn = createRecordService[dataKey];
    if (typeof mutationFn === "function" && data) {
      await mutationFn(data);
    } else {
      throw new Error("Invalid query function or date filter"); // Throw an error if the query function or date filter is not valid
    }
  };

  const { isPending } = useMutation({
    mutationFn: (dataKey, data) => createRecord(dataKey, data),
    mutationKey: [queryKeys[dataKey], data, dataKey],
    onSuccess: () => {
      showToast("Time recorded successfully", NotificationTypes.SUCCESS);
    },
    onError: () => {
      showToast("Error recording time", NotificationTypes.ERROR);
    },
  });

  return { isPending, createRecord };
};

export const useEditLog = (id, data) => {
  const { isPending } = useMutation({
    mutationFn: (id, data) => editLog(id, data),
    mutationKey: [queryKeys.editLog, id, data],
    onSuccess: () => {
      // queryClient.invalidateQueries(queryKeys.getLogs);
      showToast("Log updated successfully", NotificationTypes.SUCCESS);
    },
    onError: () => {
      showToast("Error updating log", NotificationTypes.ERROR);
    },
  });

  return { isPending, editLog };
};

export const useGetLog = async (id) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: [queryKeys.getLog, id],
    queryFn: () => getLog(id),
    meta: {
      errorMessage: "Error retrieving log details",
    },
  });

  const clientData = useMemo(() => {
    return {
      details: data?.data || [],
    };
  }, [data]);

  return {
    clientData,
    isLoading: isLoading || isRefetching,
  };
};

export const useDeleteLog = (id) => {
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: (id) => deleteLog(id),
    mutationKey: [queryKeys.deleteLog, id],
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getLogs);
      showToast("Log deleted successfully", NotificationTypes.SUCCESS);
    },
    onError: (error) => {
      showToast("Error deleting log", NotificationTypes.ERROR);
    },
  });

  return { isPending, mutate };
};
