import { useState } from "react";

function TimerToggle({ handleChange }) {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    var checked = e.target.checked;
    handleChange(checked);
    setIsChecked(checked);
  };
  return (
    <>
      <label class="inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => handleCheckboxChange(e)}
          className="sr-only peer"
        />
        <div class="relative w-11 h-6 bg-gray-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-business-primary dark:peer-focus:ring-business-primary rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-primary"></div>
        <span class="ms-3 text-lg font-semibold text-gray-900 dark:text-gray-300">
          Use Timer
        </span>
      </label>
    </>
  );
}
export default TimerToggle;
