const queryKeys = {
  signup: "sign-up",
  signin: "sign-in",
  verifyEmail: "verify-email",
  resendVerificationEmail: "resend-verification-email",
  forgotPassword: "forgot-password",
  resetPassword: "reset-password",
  changePassword: "change-password",
  getProperties: "get-properties",
  getProperty: "get-property",
  getPropertiesList: "get-properties-list",
  getSomeProperties: "get-some-properties",
  createProperty: "create-property",
  editProperty: "edit-property",
  deleteProperty: "delete-property",
  ltr: "record-LTR-time",
  str: "record-STR-time",
  getLogs: "get-logs",
  getSomeLogs: "get-some-logs",
  getLogCategories: "get-log-categories",
  getLogActivities: "get-log-activities",
  editLog: "edit-log",
  getLog: "get-log",
  deleteLog: "delete-log",
  getTeamMembers: "get-team-members",
  getTeamMembersList: "get-team-members-list",
  getRoles: "get-roles",
  inviteTeamMember: "invite-team-member",
  createTeamMember: "create-team-member",
  editTeamMember: "edit-team-member",
  deleteTeamMember: "delete-team-member",
  sendSupportMessage: "send-support-message",
  getChartData: "get-chart-data",
  updateUserDetails: "update-user-details",
  getUserPhoto: "get-user-photo",
  getSubscriptions: "get-subscriptions",
  createImportHours: "create-import-hours",
  updateUserPhoto: "update-user-photo",
  createInvitedUser: "create-invited-user",
  getUserPermissions: "get-user-permissions",
};

export default queryKeys;
